import { Apps } from '../app/core/enums';

export const environment = {
  production: false,
  app: Apps.Manager,
  isSW: false,
  brand: 'CX',
  apiUrl: 'https://dev.api.backend.cinetixx.com',
  cloudFrontUrl: 'https://d1fotccbcs48lo.cloudfront.net',
  screenerAssetsUrl: 'https://screener-assets.cinetixx.com',
  adminUrl: 'https://dev.admin.cinetixx.com',
  appUrl: 'https://dev.manager.cinetixx.com',
  fallbackLanguage: 'de',
  movieDataBaseResultsPage: 50,
  servicesDateFormat: 'YYYY-MM-DD',
  servicesDateTimeFormat: 'YYYY-MM-DD HH:mm',
  defaultDateFormat: 'dd.MM.yyyy',
  defaultTimeFormat: 'HH:mm',
  version: 'dev-4882',
  wsUrl: 'https://dev.api.backend.cinetixx.com/signalr',
  oldWsUrl: 'https://ws.cinetixx.com',
  versionPrefix: 'dev',
  dsn: "https://15cd73b9bba04e6f89cf5886e1970af5@sentry.cinetixx.com/25",
  screenerUrl: 'https://dev.screenctrl.cinetixx.com',
  screenerAwsLink: 'https://screener-assets.cinetixx.com',
  postersLink: 'https://images.cinetixx.com/posters',
  fullcalendarLicenseKey: '0972065687-fcs-1727420918'
};
