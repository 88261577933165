export enum ResolverKeys {
  LANGUAGES_SNIPPETS = 'languagesSnippets',
  ACCOUNT = 'account',
  GENRES = 'genres',
  AGE_RATINGS = 'ageRatings',
  CAST_TYPES = 'castTypes',
  MOVIE_TYPES = 'movieTypes',
  VERSION_TYPES = 'versionTypes',
  DISTRIBUTORS = 'distributors',
  COUNTRIES_MOVIE = 'countriesMovie',
  SALES_TAX = 'salesTax',
  SHOW_TYPES = 'showTypes',
  PRICES = 'prices',
  POSSIBLE_TIME_OPTIONS = 'possibleTimeOptions',
  USER = 'user',
  RELEASE_NOTES = 'releaseNotes',
  CUSTOMER = 'customer',
  PRODUCT_STATUSES = 'productStatuses',
  CINEMAS_WITH_AUDITORIUMS = 'cinemasWithAuditoriums',
  TICKET_FEES = 'ticketFees',
  PRICE_GROUPS_WITH_PRICES = 'priceGroupsWithPrices',
  TAB_PRICE_NAMES = 'tabPriceNames',
  CARD_TYPES = 'cardTypes',
  ZONES = 'zones',
  CATEGORIES = 'categories',
  ACCOUNT_REGIONS = 'accountRegions',
  KIOSKS = 'kiosks',
  PRODUCT_GROUPS = 'productGroups',
  CURRENCIES = 'currencies',
  PRODUCTS = 'products',
  LAYOUTS = 'layouts',
  TABS = 'tabs',
  INGREDIENTS = 'ingredients',
  EMPLOYEES = 'employees',
  ACTION_TYPES = 'actionTypes',
  MOVIE = 'movie',
  CINEMAS = 'cinemas',
  STREAM_GROUPS = 'streamGroups',
  STREAM = 'stream',
  STREAMS = 'streams',
  PARAMETERS = 'parameters',
  TAGS = 'tags',
  AUDITORIUMS = 'auditoriums',
  DASHBOARD = 'dashboard',
  PRODUCT = 'product',
  ANALYTICS_DETAILS = 'analyticsDetails',
  COUNTRIES = 'countries',
  MY_CINEMAS_INTERNET = 'myCinemasInternet',
  STATISTICS_SETTINGS = 'statisticsSettings',
  TERMINAL_TYPES = 'terminalTypes',
  KIOSK_LAYOUT_TYPES = 'kioskLayoutTypes',
  TERMINAL = 'terminal',
  PHONE_TYPES = 'phoneTypes',
  MANDATOR_DETAILS = 'mandatorDetails',
}
